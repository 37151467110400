var exports = {};

exports = function (str, pos) {
  if (str === null || str === undefined) {
    throw TypeError();
  }

  str = String(str);
  var size = str.length;
  var i = pos ? Number(pos) : 0;

  if (Number.isNaN(i)) {
    i = 0;
  }

  if (i < 0 || i >= size) {
    return undefined;
  }

  var first = str.charCodeAt(i);

  if (first >= 55296 && first <= 56319 && size > i + 1) {
    var second = str.charCodeAt(i + 1);

    if (second >= 56320 && second <= 57343) {
      return (first - 55296) * 1024 + second - 56320 + 65536;
    }
  }

  return first;
};

export default exports;